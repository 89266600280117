@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
  font-family: 'Raleway', sans-serif;
  /* top: 0px !important; */
}

.text-shadow {
  text-shadow: 6px 6px 4px rgba(0, 0, 0, 0.5);
}

/* scroll style changes */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #640a20a9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #640a21;
}

.parallelogram {
  background-color: #640a21;
  color: #333;
  padding: 15px;
  transform: skewX(25deg);
  rotate: 90deg;
  width: 15px;
  text-align: center;
}

.input-field {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

/* mouse hover flip effect */
.flip-card:hover {
  transform: rotateY(360deg);
}