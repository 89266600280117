.goog-te-gadget {
  display: flex !important;
  align-items: center !important;
  gap: 6px !important;
}

.goog-te-gadget > span > a {
  display: flex !important;
  align-items: flex-end !important;
  gap: 2px !important;
}

.goog-te-gadget .goog-te-combo {
  padding: 4px 4px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

.goog-te-gadget .goog-te-combo {
  outline: none !important;
}

/* .VIpgJd-ZVi9od-ORHb {
  display: none !important;
} 

body > .skiptranslate {
  display: none;
}

body {
  top: 0px !important;
} */
