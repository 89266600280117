.wrapper {
  display: inline-flex;
}

.wrapper .static-text {
  color: theme('colors.gray.600');
  font-size: 36px;
}
.wrapper .dynamic-txt {
  margin-left: 15px;
}

.dynamic-txt li {
  list-style: none;
  font-size: 36px;
  color: theme('colors.primaryColor');
  display: inline;
}

.dynamic-txt li span {
  position: relative;
}

.dynamic-txt li span::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  border-left: 2px solid theme('colors.primaryColor');
  background-color: theme('colors.white');
  animation: typing 2s steps(4) infinite;
}

@keyframes typing {
  100% {
    left: 100%;
    margin: 0 -28px 0 28px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper .static-text {
    font-size: 24px;
  }
  .dynamic-txt li {
    font-size: 24px;
    display: flex;
  }
}
